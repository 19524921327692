import { NextPageContext } from "next";

import { addCacheControl } from "src/utilities/ssr/caching";
import { validateAndDecodeParam } from "src/utilities/ssr/validateAndDecodeParam";
import { getFundraiserPageInitialProps } from "src/utility/apiClient/fundraiserPage";

export type FundraiserInitialProps = {
  nonprofitSlug: string;
  fundraiserSlug: string;
} & Partial<Awaited<ReturnType<typeof getFundraiserPageInitialProps>>>;

export const getFundraiserInitialProps = async (
  ctx: NextPageContext
): Promise<FundraiserInitialProps> => {
  const nonprofitSlug = validateAndDecodeParam(ctx, "nonprofitSlugOrUsername");
  const fundraiserSlug = validateAndDecodeParam(ctx, "fundraiserSlug");

  const props = { nonprofitSlug, fundraiserSlug };

  // Not on server, do nothing - let client-side data fetching handle this
  if (!ctx.res) {
    return props;
  }

  // Cache this SSR response at edge
  addCacheControl(ctx);

  try {
    const data = await getFundraiserPageInitialProps({
      nonprofitSlug,
      fundraiserSlug,
    });

    return { ...props, ...data };
  } catch (error) {
    ctx.res.statusCode = 404;
    return props;
  }
};
