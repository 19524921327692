
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[nonprofitSlugOrUsername]/f/[fundraiserSlug]",
      function () {
        return require("private-next-pages/[nonprofitSlugOrUsername]/f/[fundraiserSlug]/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[nonprofitSlugOrUsername]/f/[fundraiserSlug]"])
      });
    }
  